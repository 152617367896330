import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'


Vue.use(Vuex)
let authInfo = {};
let registerInfo = {};
const authInfoRaw = Cookies.get('authInfo');
const registerInfoRaw = Cookies.get('registerInfo');

if (authInfoRaw) {
  try {
    authInfo = JSON.parse(authInfoRaw);
  } catch (e) {
    console.error('Error parsing JSON from authInfo', e);
  }
}
if (registerInfoRaw) {
  try {
    registerInfo = JSON.parse(registerInfoRaw);
  } catch (e) {
    console.error('Error parsing JSON from registerInfo', e);
  }
}
export default new Vuex.Store({
  state: {
    authInfo: authInfo,
    registerInfo: registerInfo
  },
  getters: {
  },
  mutations: {
    setAuthInfo(state, data) {
      state.authInfo = data;
      Cookies.set('authInfo', JSON.stringify(data),  { expires: 1 })
    },
    setRegisterInfo(state, data) {
      state.registerInfo = data;
      Cookies.set('registerInfo', JSON.stringify(data),  { expires: 1 })
    },
  },
  actions: {
  },
  modules: {
  }
})
