import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    component: () => import('@/views/register/applicantType')
  },
  {
    path: '/registerInfo',
    component: () => import('@/views/register/registerInfo')
  },
  {
    path: '/auditPage',
    component: () => import('@/views/register/auditPage')
  }
]

const router = new VueRouter({
  routes
})

export default router
