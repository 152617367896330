import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
router.beforeEach((to, from, next) => {
  let documentTitle = '销售代理申请'
  // path 是多级的，遍历
  to.matched.forEach((path) => {
    if (path.meta.title) {
      documentTitle = path.meta.title;
    }
  });
  document.title = documentTitle;
  next();
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
